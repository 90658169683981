import type { SizeLocales } from "@lib/constants/filtersConstants";
import { shoeSizingConventions, localizationConfig } from "./localizationConfigObject";
import type { CountryCode } from "./types";


const getHostnameLocaleConfig = () => {
    if (!process.env.NEXT_PUBLIC_HOSTNAME) {
        throw new Error(`Missing env variable HOSTNAME and NEXT_PUBLIC_HOSTNAME`);
    }

    const hostNameConfig = localizationConfig[process.env.NEXT_PUBLIC_HOSTNAME];
    if (!hostNameConfig) {
        throw new Error(`No Config found for hostname ${process.env.NEXT_PUBLIC_HOSTNAME}`);
    }
    return hostNameConfig;
};

export const activeSiteConfig = getHostnameLocaleConfig();

export const {
    currency: activeSiteCurrency,
    country: activeSiteCountry,
    fractionDigits: activeSiteFractionDigits,
    liveChat: activeSiteHasLiveChat,
    smartBanner: activeSiteSmartBanner,
} = activeSiteConfig;

export const getCountryCode = activeSiteConfig?.checkout.countryCode;

export const getOrderNumberFormatRegex = activeSiteConfig?.orderNumberFormatRegex;

export const getLanguageFromLocale = (locale: string) => {
    return locale.split("-")[0];
};

export const getCheckoutForSite = activeSiteConfig?.checkout;

export const shoeConventionForSite = (activeSiteConfig?.shoeSizingConventions ||
    shoeSizingConventions) as SizeLocales[];

export const getHostnameForCountry = (countryCode: CountryCode): string | undefined => {
    const entry = Object.entries(localizationConfig).find(
        ([_, config]) => config.country === countryCode
    );
    return entry ? entry[0] : undefined;
};

export const reCaptchaIntegrationEnabled =
    activeSiteConfig.reCaptchaIntegration.checkout ||
    activeSiteConfig.reCaptchaIntegration.contactForm;

export const getCountryForSiteByLocale = (locale: string) => {
    return locale.split("-")[1];
};

export const getCurrencyForSiteByLocale = (locale: string) => {
    for (const [_, config] of Object.entries(localizationConfig)) {
        if (config.country === locale.split("-")[1]) {
            return config.currency;
        }
    }
};
