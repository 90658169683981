import axios, {
    AxiosHeaders,
    type AxiosInstance,
    type AxiosRequestConfig,
    type AxiosResponse,
} from "axios";
import { Logger, LogTag, ServiceType } from "./../monitoring/logger";

export class AxiosService {
    private axios: AxiosInstance;
    private isRequestFromBrowser = typeof window !== "undefined";

    constructor() {
        this.axios = axios.create();
        this.setupInterceptors();
    }

    // Maybe we should call this conditionally, otherwise we are passing the locale all the time.
    // We intercept the request to pass add data to the headers: https://axios-http.com/docs/interceptors
    private setupInterceptors() {
        const defaultLocale = process.env.NEXT_LOCALES?.split(",")[0];

        this.axios.interceptors.request.use((config) => {
            const headers = new AxiosHeaders(config.headers);
            headers.set("X-Ecco-Locale", defaultLocale);
            return config;
        });
    }

    // DEVELOPER NOTE

    // TODO: Add getClient fetchService method to all the client rquests
    // TODO Add YOTPO useFetch to the request (and check all get requests)
    // TODO CHECK for BasePath (removing or keeping it)
    // UseMuate instead of usePost (change naming convention)

    public async get<T>(url: string, config?: AxiosRequestConfig) {
        const startTime = performance.now();

        try {
            const response = await this.customRequest<T>({
                method: "GET",
                url,
                ...config,
            });

            Logger.info(
                this.isRequestFromBrowser ? ServiceType.WEB : ServiceType.NEXT_API,
                "AxiosService: GET Request",
                {
                    tag: LogTag.PERFORMANCE,
                    duration: performance.now() - startTime,
                    url,
                }
            );

            return response;
        } catch (error) {
            return this.handleError(error);
        }
    }

    public async post<T, R>(url: string, data: T, config?: AxiosRequestConfig) {
        const startTime = performance.now();

        try {
            const response = await this.customRequest<R>({
                method: "POST",
                url,
                data,
                headers: {
                    "Content-Type": "application/json",
                    ...config?.headers,
                },
                ...config,
            });

            Logger.info(
                this.isRequestFromBrowser ? ServiceType.WEB : ServiceType.NEXT_API,
                "AxiosService: POST Request",
                {
                    tag: LogTag.PERFORMANCE,
                    duration: performance.now() - startTime,
                    url,
                }
            );

            return response;
        } catch (error) {
            return this.handleError(error);
        }
    }

    public async delete<T>(url: string, data?: any, config?: AxiosRequestConfig) {
        try {
            return await this.customRequest<T>({
                method: "DELETE",
                url,
                data,
                headers: {
                    "Content-Type": "application/json",
                    ...config?.headers,
                },
                ...config,
            });
        } catch (error) {
            return this.handleError(error);
        }
    }

    public async put<T>(url: string, data: any, config?: AxiosRequestConfig) {
        try {
            return await this.customRequest<T>({
                method: "PUT",
                url,
                data,
                headers: {
                    "Content-Type": "application/json",
                    ...config?.headers,
                },
                ...config,
            });
        } catch (error) {
            return this.handleError(error);
        }
    }

    private async customRequest<T>(config: AxiosRequestConfig): Promise<T> {
        if (this.isRequestFromBrowser) {
            const response = await this.axios(config);
            return this.handleResponse<T>(response);
        } else {
            // We use this method for server side requests. Maybe update the naming convention is needed.
            const { default: axiosWithLogging } = await import("./fetchWithLoggingAxios");
            const response = await axiosWithLogging(config);
            return this.handleResponse<T>(response);
        }
    }

    private handleResponse<T>(response: AxiosResponse): T {
        // TODO: Check if there is consistency in the response structure to avoid this condition
        return response.data.data ?? response.data;
    }

    private handleError(error: any): Promise<never> {
        if (axios.isAxiosError(error)) {
            const status = error.response?.status ?? 500;
            const errorMessage =
                error.response?.data?.error || error.response?.data?.errors || error.message;

            return Promise.reject({ error: errorMessage, status });
        }
        return Promise.reject({ error: "Unknown error occurred", status: 500 });
    }
}

const FetchServiceAxios = new AxiosService();

export { FetchServiceAxios };
