import {
    useQuery,
    useMutation,
    type UseMutationOptions,
    type UseQueryOptions,
    type QueryKey,
} from "@tanstack/react-query";

import type { AxiosRequestConfig } from "axios";
import { FetchServiceAxios } from "./fetchServiceAxios";

// Types
export type ApiError = {
    error: string;
    status: number;
};

export type QueryOnlyConfig<TData> = Omit<
    UseQueryOptions<TData, ApiError, TData>,
    "queryKey" | "queryFn"
>;

export type QueryConfig<TData> = QueryOnlyConfig<TData> & {
    axiosConfig?: AxiosRequestConfig;
};

export type MutationConfig<TData, TVariables> = Omit<
    UseMutationOptions<TData, ApiError, TVariables>,
    "mutationFn"
> & {
    axiosConfig?: AxiosRequestConfig;
    method?: "POST" | "PUT" | "DELETE";
};
export const useApi = {
    // Async function wrapper
    /**
     *
     * @param queryKey Key of the request, useful to invalidate when need fresh data
     * @param queryFunction async function to be executed
     * @param config query
     */
    useAsync: <TData>(
        queryKey: QueryKey,
        queryFunction: () => Promise<TData>,
        config: QueryOnlyConfig<TData> = {}
    ) => {
        return useQuery<TData, ApiError>({
            queryKey,
            queryFn: async () => await queryFunction(),
            ...config,
        });
    },

    // Get wrapper
    /**
     *
     * @param queryKey Key of the request, useful to invalidate when need fresh data
     * @param url to the make the GET request
     * @param options axios config or queryConfig
     */
    useFetch: <TData>(queryKey: QueryKey, url: string, config?: QueryConfig<TData>) => {
        const { axiosConfig, ...queryConfig } = config || {};

        return useQuery<TData, ApiError>({
            queryKey,
            queryFn: async () => await FetchServiceAxios.get<TData>(url, axiosConfig),
            ...queryConfig,
        });
    },

    // POST Wrapper
    // POST, PUT or DELETE wrapper. We are using "usePost" to avoid confusion when is implemented
    // if we use useMutation, we could import react query's hook.
    /**
     *
     * @param url to the make the request, default is POST
     * @param options axios config or mutationConfig, if needed specify method, onSuccess handler, onError handlers, etc
     */
    usePost: <TData, TVariables>(url: string, config?: MutationConfig<TData, TVariables>) => {
        const { axiosConfig, method = "POST", ...mutationConfig } = config || {};

        return useMutation<TData, ApiError, TVariables>({
            mutationFn: async (variables) => {
                try {
                    switch (method) {
                        case "PUT":
                            return await FetchServiceAxios.put<TData>(url, variables, axiosConfig);
                        case "DELETE":
                            return await FetchServiceAxios.delete<TData>(
                                url,
                                variables,
                                axiosConfig
                            );
                        case "POST":
                        default:
                            return await FetchServiceAxios.post<TVariables, TData>(
                                url,
                                variables,
                                axiosConfig
                            );
                    }
                } catch (error) {
                    return error;
                }
            },
            ...mutationConfig,
        });
    },
};
