export const QueryKeys = {
    getAddresses: ["get-addresses"] as const,
    getProfile: ["get-profile"] as const,
    getServiceCloudToken: ["get-service-cloud-token"] as const,
    getPostalCode: ["get-postal-code"] as const,
    algoliaProducts: ["algolia-products"] as const,
    getYotpoReviews: ["get-yotpo-reviews"] as const,
    getPaymentMethods: ["get-payment-methods"] as const,
    getDeliveryMethods: ["get-delivery-methods"] as const,
    amazonPaySignature: "amazon-pay-signature" as const,
} as const;
