import type { Customer } from "@graphql/generated/components";
import { useRouter } from "next/router";
import { useStore } from "../store";
import { useQueryClient } from "@tanstack/react-query";
import { QueryKeys } from "@lib/enums/QueryKeys";
import { useAuth } from "@lib/auth/useAuth";
import { type ApiError, useApi } from "@lib/helpers/useApi";
import type { AddressResponse } from "@interfaces/storefront-api";

// Rest API Methods
export const useGetMyAddresses = () => {
    const { basePath } = useRouter();
    const { isAuthenticated } = useAuth();
    const setData = useStore((state) => state.setMyAddresses) as (customer: Customer) => void;

    const { data, isLoading, error } = useApi.useFetch<AddressResponse[]>(
        [QueryKeys.getAddresses],
        `${basePath}/api/addresses/get-addresses`,
        // We fetch addresses only if user is authenticated
        {
            enabled: isAuthenticated,
        }
    );

    if (data) {
        setData(data as unknown as Customer);
    }

    return {
        data,
        isLoading,
        error,
    };
};

export const useGetMyProfile = () => {
    const { basePath } = useRouter();
    const setData = useStore((state) => state.setMyProfile) as (customer: Customer) => void;
    const { isAuthenticated } = useAuth();
    const { data, isLoading, error } = useApi.useFetch(
        [QueryKeys.getProfile],
        `${basePath}/api/user/get-user`,
        // We fetch profile only if user is authenticated
        {
            enabled: isAuthenticated,
        }
    );

    if (data) {
        setData(data as Customer);
    }

    return {
        data,
        isLoading,
        error,
    };
};

export const useAddAddressToCustomer = () => {
    const { basePath } = useRouter();
    const setData = useStore((state) => state.setMyAddresses) as (customer: Customer) => void;
    const queryClient = useQueryClient();

    const mutation = useApi.usePost<any, ApiError>(`${basePath}/api/addresses/add-address`, {
        method: "POST",
        onSuccess: (data) => {
            // Invalidate and refetch addresses, so we have the new address in the list
            queryClient.invalidateQueries({ queryKey: [QueryKeys.getAddresses] });
            // Update zustand store
            setData(data as Customer);
        },
        onError: (error) => {
            return error;
        },
    });
    return { addAddressMutation: mutation };
};

export const useUpdateAddressToCustomer = () => {
    const { basePath } = useRouter();
    const queryClient = useQueryClient();
    const setData = useStore((state) => state.setMyAddresses) as (customer: Customer) => void;

    const mutation = useApi.usePost<any, any>(`${basePath}/api/addresses/update-address`, {
        method: "PUT",
        onSuccess: (data) => {
            // Invalidate and refetch addresses, so we have the new address in the list
            queryClient.invalidateQueries({ queryKey: [QueryKeys.getAddresses] });
            // Update zustand store
            setData(data);
        },
        onError: (error) => {
            return error;
        },
    });
    return { updateAddressMutation: mutation };
};

export const useRemoveAddressToCustomer = () => {
    const { basePath } = useRouter();
    const setData = useStore((state) => state.setMyAddresses) as (customer: Customer) => void;
    const queryClient = useQueryClient();
    // Type properly the requests
    const mutation = useApi.usePost<any, any>(`${basePath}/api/addresses/delete-address`, {
        method: "DELETE",
        onSuccess: (data) => {
            // Invalidate and refetch addresses, so we have the new address in the list
            queryClient.invalidateQueries({ queryKey: [QueryKeys.getAddresses] });
            // Update zustand store
            setData(data as Customer);
        },
        onError: (error) => {
            return error;
        },
    });
    return { deleteAddressMutation: mutation };
};

export const useUpdateProfile = () => {
    const { basePath } = useRouter();
    const queryClient = useQueryClient();
    const setData = useStore((state) => state.setMyProfile) as (customer: Customer) => void;

    const mutation = useApi.usePost<any, ApiError>(`${basePath}/api/user/update-user`, {
        method: "PUT",
        onSuccess: (data) => {
            // Invalidate and refetch profile, so we have the new version updated
            queryClient.invalidateQueries({ queryKey: [QueryKeys.getProfile] });
            // Update zustand store
            setData(data);
        },
        onError: (error) => {
            return error;
        },
    });
    return { updateMyProfileMutation: mutation };
};
